@font-face {
    font-family: 'Unilever';
    src: local('Unilever'), url('./assets/fonts//UnileverShilling.ttf') format('truetype');
}

@font-face {
    font-family: 'UnileverMedium';
    src: local('UnileverMedium'), url('./assets/fonts/UnileverShillingMedium.ttf') format('truetype');
}

@font-face {
    font-family: 'UnileverBold';
    src: local('UnileverBold'), url('./assets/fonts/UnileverShillingBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url('./assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSansBold'), url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: local('OpenSansBoldItalic'), url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansExtraBold';
    src: local('OpenSansExtraBold'), url('./assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansExtraBoldItalic';
    src: local('OpenSansExtraBoldItalic'), url('./assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: local('OpenSansSemiBold'), url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansSemiBoldItalic';
    src: local('OpenSansSemiBoldItalic'), url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansItalic';
    src: local('OpenSansItalic'), url('./assets/fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansLight';
    src: local('OpenSansLight'), url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansLightItalic';
    src: local('OpenSansLightItalic'), url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype');
}

body {
    font-family: Unilever !important;
    color: #474F63;
}